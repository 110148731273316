import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import AboutSummary from '../components/AboutSummary'

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="container max-w-postpage mx-auto">
      <div className="flex flex-wrap justify-around">
        <div className="w-full lg:w-content mx-4">
          <section className="bg-white pb-6">
            <section className="mb-8 pt-6">
              <h1 className="font-bold text-3xl px-6 pt-2 pb-6">
                {title}
              </h1>
            </section>
            <PageContent className="content" content={content} />
          </section>
        </div>
        <div className="w-full lg:w-sidebar h-about">
          <AboutSummary></AboutSummary>
        </div>
      </div>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
